import { Text } from '@getonnet/tixio-ui-core';
import HeroSvg from './Hero.svg';

export const Maintainance = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
        fontFamily: 'manrope',
      }}
    >
      <img src={HeroSvg} />
      <h1
        style={{
          fontSize: 34,
          textAlign: 'center',
          fontWeight: 600,
        }}
      >
        We are currently under maintenance
      </h1>
      <Text
        className="maintenance-text"
        color="#6B7280"
        size="md"
        sx={{
          width: 'min(50%, 490px)',
          textAlign: 'center',
          margin: '0px auto',
        }}
      >
        Hang on! We are under maintenance It will not take a long time till we get the error fixed and will be back
        soon.
      </Text>
    </div>
  );
};
