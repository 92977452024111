import { PlansUnion } from '@containers/Settings-And-Members/services/entity/workspace.entity';
import ReactGA from 'react-ga';
import config from '../config';
import { logrocketInit } from '../config/logRocket';

const callWindowFN = (fnName: string, ...args: any[]) => {
  if (import.meta.env.DEV) return;
  typeof window !== 'undefined' && (window as any)[fnName] && (window as any)[fnName](...args);
};

export class VendorAnalytics {
  static initAll() {
    VendorAnalytics.GoogleAnalytics();
    VendorAnalytics.LogRocketInit();
  }
  static GoogleAnalytics() {
    if (config.gaTrackingCode) {
      ReactGA.initialize(config.gaTrackingCode);
    }
  }
  static LogRocketInit() {
    if (import.meta.env.DEV) return;
    if (config.logrocketAppId) {
      logrocketInit();
    }
  }

  static onCheckoutInit() {
    callWindowFN('gtag', 'event', 'begin_checkout', {
      // send_to: config.gAdsTrackingCode,
    });

    // callWindowFN('fbq', 'track', 'InitiateCheckout');
  }

  static onTrialStart() {
    callWindowFN('gtag', 'event', 'begin_trial', {
      // send_to: config.gAdsTrackingCode,
    });

    // callWindowFN('fbq', 'track', 'StartTrial', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
  }

  static onSignupPageVisited() {
    callWindowFN('gtag', 'event', 'sign_up_page_visited', { send_to: config.gAdsTrackingCode });
  }

  static onRegistrationComplete() {
    callWindowFN('gtag', 'event', 'sign_up_complete', {
      // send_to: config.gAdsTrackingCode,
    });

    // callWindowFN('fbq', 'track', 'CompleteRegistration');
  }

  static onPurchaseComplete({
    price,
    ...data
  }: {
    price: number;
    plan: PlansUnion;
    totalMembers: number;
    frequency: 'monthly' | 'yearly';
  }) {
    console.log('conversion event called', {
      price,
      ...data,
    });
    callWindowFN('gtag', 'event', 'conversion', {
      // send_to: config.gAdsTrackingCode,
      conversionValue: price,
      currency: 'USD',
      ...data,
    });

    // callWindowFN('fbq', 'track', 'Subscribe', {
    //   value: price.toString(),
    //   currency: 'USD',
    //   predicted_ltv: price.toString(),
    // });
  }
}
